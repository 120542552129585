<template>
  <div class="page">
    <div class="card">
      <p style="width: 100%;text-align: center">活动类型分析</p>
      <Chart type="pie" :data="eventType" />
    </div>
<!--    <div class="card">-->
<!--      <p style="width: 100%;text-align: center">智库增长趋势图</p>-->
<!--      <Chart type="line" :data="expertNumber"/>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
name: "eventType",
  data() {
    return {
      eventType:null,
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    getDate() {

      this.$http.get('/Statistics/event').then((res) => {
        console.log(res.data)
        let eventType = res.data.eventType
        let labels = []
        let data = []

        for (let i = 0; i < eventType.length; i++) {
          labels.push(eventType[i].type)
          data.push(eventType[i].num)
        }
        this.eventType = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726"
              ],
              hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
              ]
            }
          ]
        }
      })
    }
  }
}
</script>

<style scoped>
.card{
  width: calc(50% - 7px);
}
.page{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: none;
  border: none;
}
</style>